import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import { Container } from "react-bootstrap"

export default function Layout({ children }) {
  return (
    <div className="containerLayout">
      <Header hiddenVideo={true} />
      <Container fluid>{children}</Container>
      <Footer />
    </div>
  )
}
