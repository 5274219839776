import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

export default function NotFound() {
  return (
    <Layout>
      <SEO title="404" />
      <div>
        <h2>404</h2>
        <p>Sorry, that page doesn't exist</p>
      </div>
    </Layout>
  )
}
